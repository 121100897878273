<template>
  <div>
    <v-row justify="center" class="text-center">
      <v-col cols="auto">
        <h3 class="headline">{{ $t("m.welcome", { name: serviceName }) }}</h3>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <v-progress-circular
          v-show="!isSignInUILoaded"
          :size="50"
          color="primary"
          indeterminate
        />
      </v-col>
    </v-row>
    <div id="firebaseui-auth-container" />
    <Popup
      v-bind="showSignInPopupProp"
      @confirm="showSignInCallback"
      @update:showDialog="showSignInCallback"
    />
  </div>
</template>

<script>
import { firebase } from '@/helpers/firebase'
import { firestore } from '@/helpers/firestore'
import { serviceName } from '@/truffle.config'
import { COLLECTIONS_ENUM } from '@/enums'
import { mapActions } from 'vuex'
import Popup from '@/components/calendar/Popup'
import { removeUser } from '@/helpers/firebase-functions'

const providerMap = {
  phone: {
    provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    defaultCountry: 'JP',
    recaptchaParameters: {
      size: 'invisible'
    },
    whitelistedCountries: ['JP']
  },
  facebook: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  google: {
    provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    customParameters: {
      prompt: 'select_account'
    }
  },
  email: {
    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    requireDisplayName: false
  }
}

const userRef = uid => firestore
  .collection(COLLECTIONS_ENUM.USERS)
  .doc(uid)

export default {
  metaInfo () {
    const providers = process.env.VUE_APP_PROVIDERS.split(',')
    const signInOptions = providers.map(provider => providerMap[provider])
    return {
      script: [
        {
          callback: () => {
            window.firebase = firebase
            const ui = new window.firebaseui.auth.AuthUI(firebase.auth())
            this.authUI = ui
            const uiConfig = {
              callbacks: {
                uiShown: () => { this.isSignInUILoaded = true },
                signInSuccessWithAuthResult: (authResult) => {
                  const login = () => this.login(authResult.user).then(() => {
                    this.$router.replace('/')
                  })
                  const allowOnlyAdminCreated = process.env.VUE_APP_DENY_SELF_CREATED_USER === 'true'
                  const denyNewUser = process.env.VUE_APP_DENY_NEW_USER === 'true'

                  const { additionalUserInfo: { isNewUser } } = authResult
                  if (denyNewUser && isNewUser) {
                    this.showSignInCallback = () => {
                      this.signInPopupLoader = true

                      removeUser().then(() => {
                        this.showSignInPopup = false
                        this.signInPopupLoader = false
                        this.isSignInUILoaded = true
                        ui.start('#firebaseui-auth-container', uiConfig)
                      })
                    }
                    this.showSignInPopup = true
                  } else if (allowOnlyAdminCreated) {
                    const { uid } = authResult.user
                    this.checkAdminCreated(uid).then(isAdminCreated => {
                      if (isAdminCreated) {
                        login()
                      } else {
                        this.showSignInCallback = () => {
                          this.signInPopupLoader = true
                          firebase.auth().signOut().then(() => {
                            this.showSignInPopup = false
                            this.signInPopupLoader = false
                            this.isSignInUILoaded = true
                            ui.start('#firebaseui-auth-container', uiConfig)
                          })
                        }
                        this.showSignInPopup = true
                      }
                    })
                  } else {
                    login()
                  }
                  return false
                }
              },
              signInOptions,
              credentialHelper: window.firebaseui.auth.CredentialHelper.NONE,
              // Terms of service url/callback.
              tosUrl: 'https://legal.truffle.ai/',
              // Privacy policy url/callback.
              privacyPolicyUrl: 'https://legal.truffle.ai/privacy-policy/'
            }

            ui.start('#firebaseui-auth-container', uiConfig)
          },
          src: this.scriptSource,
          body: true
        }
      ],
      link: [
        {
          type: 'text/css',
          rel: 'stylesheet',
          href: 'https://www.gstatic.com/firebasejs/ui/4.2.0/firebase-ui-auth.css'
        }
      ]
    }
  },
  components: {
    Popup
  },
  props: {
    redirect: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      serviceName,
      isSignInUILoaded: false,
      authUI: null,
      showSignInPopup: false,
      signInPopupLoader: false,
      showSignInCallback: () => { }
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    checkAdminCreated: async (userId) => {
      const { company } = await userRef(userId).get().then(doc => doc.data())
      return company !== undefined
    }
  },
  computed: {
    scriptSource () {
      const browserLocale = navigator.language.split('-')[0]
      return browserLocale === 'ja'
        ? 'https://www.gstatic.com/firebasejs/ui/4.2.0/firebase-ui-auth__ja.js'
        : 'https://www.gstatic.com/firebasejs/ui/4.2.0/firebase-ui-auth__en.js'
    },
    showSignInPopupProp () {
      return {
        showDialog: this.showSignInPopup,
        title: this.$i18n.t('m.signin.notRegistered'),
        confirm: this.$i18n.t('m.ok'),
        confirmLoading: this.signInPopupLoader
      }
    }
  },
  destroyed () {
    this.authUI.delete()
  }
}
</script>

<style>
/* Collapse height of firebase ui loading spinner container */
.mdl-card .firebaseui-callback-indicator-container {
  height: 2px;
}

/* Collapse height of firebase ui loading spinner container */
.mdl-card.firebaseui-container {
  min-height: 2px;
}

#firebaseui-auth-container ul.firebaseui-idp-list {
  padding-left: 0;
}
</style>
