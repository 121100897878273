<template>
  <v-dialog v-model="show" :width="width">
    <v-card>
      <v-card-title class="header">{{title}}</v-card-title>
      <v-card-subtitle v-if="subtitle !== ''">{{subtitle}}</v-card-subtitle>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :color="color"
          v-if="cancel != ''"
          text
          :disabled="confirmLoading"
          @click="click('cancel')"
        >{{ cancel }}</v-btn>
        <v-btn :color="color" :loading="confirmLoading" @click="click('confirm')">{{ confirm }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    showDialog: { default: () => { return false } },
    width: { default: () => { return 400 } },
    title: { default: () => { return '' } },
    subtitle: { default: () => { return '' } },
    confirm: { default: () => { return 'Confirm' } },
    cancel: { default: () => { return '' } },
    color: { default: () => { return 'primary' } },
    confirmLoading: { default: () => { return false } }
  },
  methods: {
    click (event) {
      this.$emit(event)
    }
  },
  computed: {
    show: {
      get () {
        return this.showDialog
      },
      set (val) {
        this.$emit('update:showDialog', val)
      }
    }
  }
}
</script>
