<template>
  <SignIn :redirect="redirect" />
</template>

<script>
import SignIn from '@/components/sign-in/firebase-sign-in-ui'
import { EVENT_NAMES, analyticsEvent } from '@/helpers/analytics'
import { mapMutations } from 'vuex'

export default {
  components: {
    SignIn
  },
  props: {
    redirect: {
      type: String,
      default: '/'
    }
  },
  methods: {
    ...mapMutations('auth', ['updateIsSigningOut'])
  },
  mounted () {
    analyticsEvent(EVENT_NAMES.LOADED_SIGN_IN)
  }
}
</script>

<style>
</style>
